.overview {
    .item {
        display: inline-block;
        margin: 0.5rem;
    }
}
.entry {
    margin-bottom: 4rem;
    tr:first-child {
        font-weight: bold;
        width: 150px;
    }
    td {
        line-height: 1.5rem;
        padding-right: 1rem;
    }
    .entryline {
        display: flex;
        align-items: center;
        justify-content: center;
        > img {
            padding-right: 2rem;
        }
    }
}
