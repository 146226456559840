@import-normalize; /* bring in normalize.css styles */
@import 'react-bnb-gallery/dist/style.css';
@import './styles/constants';
html,
body,
#root {
    height: 100%;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $fw--color-main;
}

a,
a:link,
a:visited {
    color: $fw--color-main;
    &:active,
    &:hover {
        color: $fw--color-highlight;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
    font-size: 1.2rem;
    color: $fw--color-second;
    font-weight: bold;
}

h2 {
    font-size: 1rem;
    color: $fw--color-second;
    font-weight: bold;
}

.thumbnail-button {
    border-color: black;
    border-style: solid;
}
