@import '../styles/constants';
.container {
    height: 100%;
    min-width: 165px;
    padding-right: 16px;
    position: relative;
    .greenbar {
        background-color: $fw--color-main;
        width: 135px;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
    }
    .alpaka {
        width: 100%;
        position: absolute;
        top: 14px;
        bottom: 0;
        left: 0;
        right: 0;
        background: {
            image: url('../static/MenuAlpaka.gif');
            repeat: no-repeat;
            position: 0 0;
        }
    }
    .content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        .menu {
            margin: 250px 16px 50px 16px;
            padding: 8px 16px;
            border: 2px solid $fw--color-main;
            background-color: white;
            // font-weight: bold;
            a {
                text-decoration: none;
            }
            li {
                padding-bottom: 4px;
            }
        }
        .guesthouse {
            background: url('../static/smallLogo.gif') no-repeat;
            width: 149px;
            height: 41px;
            margin: 32px auto 0;
        }
        .azvd {
            background: url('../static/arvde.gif') no-repeat;
            width: 73px;
            height: 73px;
            margin: 16px auto 0;
        }
    }
}
